import axios from "axios";
import { useEffect, useState } from "react";
import { EquipmentAuditState } from "./types";

function Completed(props: { currentState: EquipmentAuditState, setCurrentState: any, goBack: any }) {
  const [serviceRequestId, setServiceRequestId] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post('https://servicedesk-automation.substantial-group.workers.dev/api/v1/tickets/new-audit', props.currentState);
        setServiceRequestId(response.data.serviceRequestId);
      } catch (e: any) {
        console.error(e);
        props.goBack();
        alert('Failed to submit audit.\n\nReason: ' + e.response.data.message);
      }
    })();
  }, [props.currentState]);

  return (!serviceRequestId ? <>One moment, please...</> : <>
    <div className="mb-5">
      <div className="alert alert-success">
        <p><strong>Thank you!</strong></p>
        <p>Your submission has been recorded. You may at any point email <strong>itsupport@substantial.group</strong> referencing <strong>#SR-{serviceRequestId}</strong>.</p>
        <p className="mb-0">Please keep an eye out for any emails, texts, or phone calls from IT Support since we occasionally need to clarify some of the details you provided.</p>
      </div>
    </div>

    <h2>Contact Details</h2>
    <p>Here are the contact details you provided.</p>
    <table className="table table-bordered mb-5">
      <tbody>
        <tr>
          <td><strong>First Name</strong></td>
          <td>{props.currentState.contactDetails_firstName}</td>
        </tr>
        <tr>
          <td><strong>Last Name</strong></td>
          <td>{props.currentState.contactDetails_lastName}</td>
        </tr>
        <tr>
          <td><strong>Email Address</strong></td>
          <td>{props.currentState.contactDetails_emailAddress}</td>
        </tr>
      </tbody>
    </table>

    <h2>Your Assets</h2>
    <p>We use this just to double check that our inventory is correct.</p>
    <div className="mb-5">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Asset Tag</th>
            <th>Type</th>
            <th>Condition</th>
          </tr>
        </thead>
        <tbody>
          {props.currentState.assets.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center text-muted">You have not added any assets yet.</td>
            </tr>
          ) : (
            props.currentState.assets.map((asset) => {
              return <tr key={props.currentState.assets.indexOf(asset)}>
                <td>{asset.assetTag}</td>
                <td>{asset.type}</td>
                <td>{asset.condition}</td>
              </tr>
            })
          )}
        </tbody>
      </table>
    </div>
  </>);
}

export default Completed;