import { EquipmentAuditState } from "./types";

function ContactDetailsForm(props: { currentState: EquipmentAuditState, setCurrentState: any }) {
  return (<>
    <div className="mb-3">
      <label htmlFor="firstName" className="form-label">First Name</label>
      <input type="text" className="form-control" id="firstName" placeholder="John" value={props.currentState.contactDetails_firstName} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'contactDetails_firstName': e.target.value
        })
      }} />
    </div>

    <div className="mb-3">
      <label htmlFor="lastName" className="form-label">Last Name</label>
      <input type="text" className="form-control" id="lastName" placeholder="Doe" value={props.currentState.contactDetails_lastName} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'contactDetails_lastName': e.target.value
        })
      }} />
    </div>

    <div className="mb-3">
      <label htmlFor="emailAddress" className="form-label">Email Address (please use the same email as your Microsoft account)</label>
      <input type="email" className="form-control" id="emailAddress" placeholder="john.doe@example.com" value={props.currentState.contactDetails_emailAddress} onChange={(e) => {
        props.setCurrentState({
          ...props.currentState,
          'contactDetails_emailAddress': e.target.value
        })
      }} />
    </div>
  </>);
}

export default ContactDetailsForm;