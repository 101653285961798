import { useState } from "react";
import { Asset, EquipmentAuditState } from "./types";

function AssetForm(props: { currentState: EquipmentAuditState, setCurrentState: any }) {
  const [naBlocker, setNABlocker] = useState<boolean>(true);
  const [formAssetTag, setFormAssetTag] = useState<string>('');
  const [formAssetType, setFormAssetType] = useState<string>('Laptop');
  const [formAssetCondition, setFormAssetCondition] = useState<string>('Perfect');

  const assetTypes = [
    {
      value: 'Laptop',
      label: 'Laptop'
    },
    {
      value: 'Tablet',
      label: 'Tablet (iPad, Samsung Galaxy Tab, etc.)'
    },
    {
      value: 'Display',
      label: 'Display (Computer Screen)'
    },
    {
      value: 'Mouse',
      label: 'Mouse'
    },
    {
      value: 'Keyboard',
      label: 'Keyboard'
    },
    {
      value: 'CableOrAdapter',
      label: 'Cable / Adapter'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ];

  const assetConditions = [
    {
      value: 'Perfect',
      label: 'Perfect - no cosmetic or technical defects.'
    },
    {
      value: 'LightWear',
      label: 'Light Wear - some light cosmetic wear, but nothing serious.'
    },
    {
      value: 'Damaged',
      label: 'Damaged - heavy cosmetic defects such as cracked screen or bends.'
    },
    {
      value: 'Inoperable',
      label: 'Inoperable - the device is unusable.'
    }
  ];

  const addNewAsset = () => {
    if (naBlocker && ['n/a', 'n/ a', 'n / a', 'n /a', 'na', ''].includes(formAssetTag.trim().toLowerCase())) {
      alert('We have detected that you did not fill in a valid serial number or asset tag. Please check your device carefully for any small "Service Tag", "Serial Number", "S/N", "S/T" or other similar markings and enter them if visible. If you are certain there is no appropriate value, please submit again.');
      setNABlocker(false);
      return;
    }

    if (![
      formAssetTag,
      formAssetType,
      formAssetCondition
    ].every((v) => v)) {
      alert('Please ensure all fields are filled in correctly.');
      return;
    }

    props.setCurrentState({
      ...props.currentState,
      assets: [
        ...props.currentState.assets,
        {
          assetTag: formAssetTag,
          type: formAssetType,
          condition: formAssetCondition
        }
      ]
    });

    setFormAssetTag('');
    setFormAssetType('Laptop');
    setFormAssetCondition('Perfect');
    setNABlocker(true);
  };

  const editAsset = (asset: Asset) => {
    setFormAssetTag(asset.assetTag);
    setFormAssetType(asset.type);
    setFormAssetCondition(asset.condition);
    setNABlocker(true);
    removeAsset(asset);
  };

  const removeAsset = (asset: Asset) => {
    const currentAssets = props.currentState.assets;
    props.setCurrentState({
      ...props.currentState,
      assets: currentAssets.filter((_asset) => _asset !== asset)
    });
  };
  
  return (<>
    <div className="card card-body mb-4">
      <h3>Add New Asset</h3>
      <div className="mb-3">
        <label htmlFor="assetTag" className="form-label">
          Asset Tag, Serial Number, or Service Tag<br />
          <em>This is usually found on the rear of your device but may be positioned elsewhere.<br />You may see the letters "S/N" or "S/T" next to it, or it may be on a sticker.</em>
        </label>
        <input type="text" className="form-control" id="assetTag" value={formAssetTag} placeholder="SG-ASSET-123" onChange={(e) => { setFormAssetTag(e.target.value); }} />
        <em>If you cannot find an appropriate value, please put N/A.</em>
      </div>

      <div className="mb-3">
        <label htmlFor="assetType" className="form-label">Asset Type</label>
        <select className="form-control" id="assetType" onChange={(e) => { setFormAssetType(e.target.value); }} value={formAssetType}>
          {assetTypes.map((assetType) => {
            return <option key={assetTypes.indexOf(assetType)} value={assetType.value}>{assetType.label}</option>;
          })}
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="assetCondition" className="form-label">Asset Condition</label>
        <select className="form-control" id="assetCondition" onChange={(e) => { setFormAssetCondition(e.target.value); }} value={formAssetCondition}>
          {assetConditions.map((assetCondition) => {
            return <option key={assetConditions.indexOf(assetCondition)} value={assetCondition.value}>{assetCondition.label}</option>;
          })}
        </select>
      </div>

      <div className="text-end">
        <button type="button" className="btn btn-primary" onClick={addNewAsset}>Add Asset</button>
      </div>
    </div>

    <div className="mb-3">
      <div className="alert alert-info">Please ensure all of your company devices are accounted for below.</div>
    </div>

    <div className="mb-3">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Asset Tag</th>
            <th>Type</th>
            <th>Condition</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.currentState.assets.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center text-muted">You have not added any assets yet.</td>
            </tr>
          ) : (
            props.currentState.assets.map((asset) => {
              return <tr key={props.currentState.assets.indexOf(asset)}>
                <td>{asset.assetTag}</td>
                <td>{asset.type}</td>
                <td>{asset.condition}</td>
                <td>
                  <a href="#" onClick={() => { editAsset(asset); }}>Edit</a>
                  &nbsp;/&nbsp;
                  <a href="#" onClick={() => { removeAsset(asset); }}>Remove</a>
                </td>
              </tr>
            })
          )}
        </tbody>
      </table>
    </div>
  </>);
}

export default AssetForm;