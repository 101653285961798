import axios from 'axios';
import { useState } from 'react';
import AssetForm from './AssetForm';
import Completed from './Completed';
import ConfirmationForm from './ConfirmationForm';
import ContactDetailsForm from './ContactDetailsForm';
import NotFound from './NotFound';
import { Asset, EquipmentAuditState } from './types';

function AuditExperience() {
  const pageActions: any = {
    'contact-details': {
      back: undefined,
      continue: async () => {
        if (![
          currentState.contactDetails_firstName,
          currentState.contactDetails_lastName,
          currentState.contactDetails_emailAddress,
        ].every((v) => v)) {
          alert('Please ensure you fill in all of your contact details.');
          return;
        }

        const emailValid = await emailIsValid(currentState.contactDetails_emailAddress || '');
        if (!emailValid) {
          alert('Please ensure that your email address has been entered correctly.\n\nIf you have both a long and short company email, try entering your long-form email.\n\nFor example, you may have john@substantial.group and john.doe@substantial.group, but john.doe@substantial.group might be the one we require.');
          return;
        }

        setCurrentView('assets');
      }
    },
    'assets': {
      back: () => { setCurrentView('contact-details'); },
      continue: () => {
        if (!window.confirm(
          '******* ATTENTION *******\n' +
          'Have you forgotten something?\n\n' +
          'Please ensure your list contains *all* of your company devices, including mice, keyboards, laptops, screens, iPads, Samsung Galaxy Tabs, cables, etc.\n\n' +
          'If it is possible you have forgotten something, please click CANCEL.')
        ) {
          return;
        }

        if (currentState.assets.length === 0) {
          alert('Please ensure you have added all of your company assets.');
          return;
        }
        setCurrentView('confirmation');
      }
    },
    'confirmation': {
      back: () => { setCurrentView('assets'); },
      continue: () => {
        setCurrentView('completed');
      }
    },
    'completed': {
      back: undefined, //() => setCurrentView('confirmation'),
      continue: undefined
    }
  };

  const [currentView, setCurrentView] = useState<string>('contact-details');
  const [currentState, setCurrentState] = useState<EquipmentAuditState>({
    'contactDetails_firstName': undefined,
    'contactDetails_lastName': undefined,
    'contactDetails_emailAddress': undefined,
    assets: new Array<Asset>()
  });

  async function emailIsValid(email: string) {
    return await (await axios.post('https://servicedesk-automation.substantial-group.workers.dev/api/v1/aad/validate', { upn: email })).data.exists;
  }

  function renderCurrentView() {
    switch (currentView) {
      case 'contact-details':
        return <ContactDetailsForm currentState={currentState} setCurrentState={setCurrentState} />;
      case 'assets':
        return <AssetForm currentState={currentState} setCurrentState={setCurrentState} />;
      case 'confirmation':
        return <ConfirmationForm currentState={currentState} setCurrentState={setCurrentState} />;
      case 'completed':
        return <Completed currentState={currentState} setCurrentState={setCurrentState} goBack={() => setCurrentView('confirmation')} />;
      default:
        return <NotFound />;
    }
  }

  return (
    <div className="container-fluid my-5">
      <div className="row align-items-center justify-content-center mb-2">
        <div className="col-11 col-md-8 col-lg-7">
          <h1>Equipment Audit</h1>
          <p className="lead">Please help us verify details we hold about your devices.</p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center mb-5">
        <div className="col-11 col-md-8 col-lg-7">
          <nav className="nav nav-pills flex-column flex-sm-row">
            <span className={`flex-sm-fill text-sm-center nav-link ${currentView==='contact-details' ? 'active' : 'disabled'}`}>1. Contact Details</span>
            <span className={`flex-sm-fill text-sm-center nav-link ${currentView==='assets' ? 'active' : 'disabled'}`}>2. Your Assets</span>
            <span className={`flex-sm-fill text-sm-center nav-link ${currentView==='confirmation' ? 'active' : 'disabled'}`}>3. Confirmation</span>
          </nav>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-11 col-md-8 col-lg-7">
          {renderCurrentView()}

          <div className="text-end mt-5">
            {pageActions[currentView].back ? (
              <button type="button" className="btn btn-dark float-start" onClick={() => pageActions[currentView].back()}>Go Back</button>
            ) : <></>}

            {pageActions[currentView].continue ? (
              <button type="button" className="btn btn-primary" onClick={() => pageActions[currentView].continue()}>Continue</button>
            ) : <></>}
          </div>

          {(currentView === 'contact-details' ? (
            <div className="text-center mt-5">
              <p>Want to check if your Direct Reports have submitted their audit? <a href="/audit-progress">View your team's progress.</a></p>
            </div>
          ) : <></>)}
        </div>
      </div>
    </div>
  );
}

export default AuditExperience;
