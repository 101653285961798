import { EquipmentAuditState } from "./types";

function ConfirmationForm(props: { currentState: EquipmentAuditState, setCurrentState: any }) {
  return (<>
    <div className="mb-5">
      <div className="alert alert-info">Please confirm all of the details below before continuing.</div>
    </div>

    <h2>Contact Details</h2>
    <p>Here are the contact details you provided.</p>
    <table className="table table-bordered mb-5">
      <tbody>
        <tr>
          <td><strong>First Name</strong></td>
          <td>{props.currentState.contactDetails_firstName}</td>
        </tr>
        <tr>
          <td><strong>Last Name</strong></td>
          <td>{props.currentState.contactDetails_lastName}</td>
        </tr>
        <tr>
          <td><strong>Email Address</strong></td>
          <td>{props.currentState.contactDetails_emailAddress}</td>
        </tr>
      </tbody>
    </table>

    <h2>Your Assets</h2>
    <p>We use this just to double check that our inventory is correct.</p>
    <div className="mb-5">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Asset Tag</th>
            <th>Type</th>
            <th>Condition</th>
          </tr>
        </thead>
        <tbody>
          {props.currentState.assets.length === 0 ? (
            <tr>
              <td colSpan={6} className="text-center text-muted">You have not added any assets yet.</td>
            </tr>
          ) : (
            props.currentState.assets.map((asset) => {
              return <tr key={props.currentState.assets.indexOf(asset)}>
                <td>{asset.assetTag}</td>
                <td>{asset.type}</td>
                <td>{asset.condition}</td>
              </tr>
            })
          )}
        </tbody>
      </table>
    </div>

    <h2>Something not right?</h2>
    <p>Click the "Go Back" button below to revise your answers.</p>
  </>);
}

export default ConfirmationForm;