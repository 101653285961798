import axios from 'axios';
import { useState } from 'react';

function ProgressExperience() {
  const [emailAddress, setEmailAddress] = useState<string>();
  const [statuses, setStatuses] = useState<{upn: string; submitted: boolean; ticketId?: number;}[]>();

  async function submitForm() {
    if (!emailAddress) {
      alert('Please enter your email address to continue.');
      return;
    }

    const emailValid = await (await axios.post('https://servicedesk-automation.substantial-group.workers.dev/api/v1/aad/validate', { upn: emailAddress })).data.exists;
    if (!emailValid) {
      alert('Please ensure that your email address has been entered correctly.\n\nIf you have both a long and short company email, try entering your long-form email.\n\nFor example, you may have john@substantial.group and john.doe@substantial.group, but john.doe@substantial.group might be the one we require.');
      return;
    }

    const statuses = await (await axios.get(`https://servicedesk-automation.substantial-group.workers.dev/api/v1/custom-objects/equipment-audit-submissions/${emailAddress}`)).data.statuses;
    setStatuses(statuses);
  }
  
  return (
    <div className="container-fluid my-5">
      <div className="row align-items-center justify-content-center mb-2">
        <div className="col-11 col-md-8 col-lg-7">
          <h1>Equipment Audit</h1>
          <p className="lead">Check how your team is doing with their Equipment Audit.</p>
        </div>
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-11 col-md-8 col-lg-7">
          {(statuses ? <>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Email Address</th>
                  <th>Submission Status</th>
                  <th>Ticket ID (if submitted)</th>
                </tr>
              </thead>
              <tbody>
                {statuses.map((s) => {
                  return (<tr>
                    <td>{s.upn}</td>
                    <td>{s.submitted ? '✅ Submitted' : '❌ Not Submitted'}</td>
                    <td>{s.ticketId || ''}</td>
                  </tr>);
                })}
              </tbody>
            </table>
            <div className="text-end mt-5">
              <a href="/" className="btn btn-dark float-start">Go Back</a>
              <button type="button" className="btn btn-primary" onClick={() => submitForm()}>Refresh</button>
            </div>
          </> : <>
            <div className="mb-3">
              <label htmlFor="emailAddress" className="form-label">Email Address</label>
              <input type="text" className="form-control" name="emailAddress" value={emailAddress} placeholder="john.doe@substantial.group" onChange={(e) => setEmailAddress(e.target.value)} />
            </div>
            
            <div className="text-end mt-5">
              <a href="/" className="btn btn-dark float-start">Go Back</a>
              <button type="button" className="btn btn-primary" onClick={() => submitForm()}>Continue</button>
            </div>
          </>)}
        </div>
      </div>
    </div>
  );
}

export default ProgressExperience;
