import {BrowserRouter, Routes, Route} from 'react-router-dom';
import AuditExperience from './AuditExperience';
import ProgressExperience from './ProgressExperience';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/audit-progress" element={<ProgressExperience />}/>
        <Route path="/" element={<AuditExperience />}/>
      </Routes>
    </BrowserRouter>
  );
};

export default App;